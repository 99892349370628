export const MODELS = {
  'text-davinci-003': 'text-davinci-003',
  'gpt-3.5-turbo': 'gpt-3.5-turbo',
  'gpt-4': 'gpt-4'
};

export const getFirstDefinedValue = (...values) => {
  for (const val of values) {
    if (val !== null && val !== undefined) {
      return val;
    }
  }
};

export const parameterizeString = (inputString, parameters) => {
  return inputString.replace(/\{(\w+)\}/g, function (match, key) {
    match = match.trim();
    key = key.trim();
    return parameters[key] !== undefined ? parameters[key] : match;
  });
}

export const getAlignmentDataInString = (inputString) => {
  const pattern = /\{\{(\w+)\.(\w+)(?:#(\d+))?\}\}/g;
  let match;
  const results = [];

  while ((match = pattern.exec(inputString)) !== null) {
    // Destructure the match array to get the captured groups
    const [, namespace, variableName, revisionNumber] = match;
    results.push({
      namespace,
      variableName,
      revisionNumber: revisionNumber || null, // If revisionNumber is undefined, set it to null
    });
  }

  return results.length > 0 ? results : null; // Return null if no matches found
}

export const parameterizeAlignmentDataString = (inputString, parameters) => {
  const pattern = /\{\{\s*(\w+)(?:((?:\.\w+)*)(?:#(\d+))?)?\s*\}\}/g;

  return inputString.replace(pattern, function (match, namespace, variableName, revisionNumber) {
    // Remove the double curly braces from either side of the match
    const key = match.replace(/\{|\}/g, '').trim();

    //console.log('key: ', key);

    // Get the value
    const value = parameters[key];

    // If value is an object, stringify it
    if (typeof value === 'object') {
      return JSON.stringify(value);
    }

    // If value exists, return it, otherwise return the original match
    return value !== undefined ? value : match;
  });
}

export const parameterizeAlignmentDataStringNested = (inputString, parameters) => {
  // Adjusted pattern to capture longer paths
  const pattern = /\{\{(\w+)((?:\.\w+)+)(?:#(\d+))?\}\}/g;

  return inputString.replace(pattern, function (match, namespace, path, revisionNumber) {
    // Split the path into parts and remove the first empty string due to the leading dot
    const pathParts = path.split('.').slice(1);

    // Navigate through the parameters object based on the path parts
    let value = parameters[namespace];
    for (const part of pathParts) {
      if (value && typeof value === 'object') {
        value = value[part];
      } else {
        // If the path cannot be fully navigated, return the original match
        return match;
      }
    }

    // If value is an object, stringify it
    if (typeof value === 'object') {
      return JSON.stringify(value);
    }

    // If value exists, return it, otherwise return the original match
    return value !== undefined ? value : match;
  });
}


export const extractJSONFromString = (s) => {
  let start_index = -1;
  // Find the start of the JSON object or array
  for (let i = 0; i < s.length; i++) {
    if (s[i] === '{' || s[i] === '[') {
      start_index = i;
      break;
    }
  }

  if (start_index !== -1) {
    s = s.substring(start_index);

    try {
      // Try parsing the JSON
      return JSON.parse(s);
    } catch (e) {
      if (e instanceof SyntaxError) {
        // If parsing fails, slice the string at the error position (approximation) and try again
        try {
          return JSON.parse(s.substring(0, e.message.match(/position (\d+)/)[1]));
        } catch (e) {
          // If it still fails, return null
          return null;
        }
      }
    }
  }

  // No JSON object or array start character found
  return null;
}

export const removeJSONFromString = (inputString) => {
  // Regular expression to find a JSON object
  const jsonRegex = /{.*?}/gs;

  // Remove the JSON object
  const modifiedString = inputString.replace(jsonRegex, '');

  return modifiedString.trim();
}

export const jsonToCSV = (jsonArray) => {
  if (jsonArray.length === 0) {
    return "";
  }

  // Extract headers and trim spaces
  const headers = Object.keys(jsonArray[0]).map(header => header.trim());
  const csvRows = jsonArray.map(row =>
    headers.map(fieldName => {
      // Ensure the value is a string before trimming
      const value = row[fieldName] == null ? "" : String(row[fieldName]);
      return JSON.stringify(value.trim(), csvFieldReplacer);
    }).join(',')
  );

  csvRows.unshift(headers.join(',')); // Add header row
  return csvRows.join('\r\n');
}

export const csvFieldReplacer = (key, value) => {
  // Filtering out properties
  if (typeof value === 'string') {
    return value.replace(/"/g, '""'); // Escape double quotes
  }
  return value;
}

export const hasAgentRevisionChanged = (prev, current) => {
  // List of properties to compare
  const propertiesToCompare = [
    'name', 'temperature', 'topP', 'maxTokens',
    'scrubPii', 'piiThreshold', 'masterPrompt', 'introductoryText',
    'streamResponses', 'model', 'imageDimensions', 'allowPublicSharing', 'customShareUrl',
    'smartDocEvaluation', 'smartDocChunkSize', 'smartDocChunkOverlap', 'smartDocEmbeddingModel',
    'smartDocTopK', 'hasPhoneNumber', 'phoneNumber'
  ];

  // Automatically compare the specified properties
  return propertiesToCompare.some(property => {
    if (Array.isArray(prev[property]) && Array.isArray(current[property])) {
      // If the property is an array, compare lengths and contents
      return prev[property].length !== current[property].length ||
             prev[property].some((item, index) => item !== current[property][index]);
    } else {
      // For non-array properties, a simple comparison
      return prev[property] !== current[property];
    }
  });
};
import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import routes from 'src/config/routes';
import { useAuth } from 'src/contexts/AuthContext';
import SnackbarContext from 'src/contexts/SnackbarContext';

export const AlignmentDataContext = createContext();

export const AlignmentDataProvider = ({ children }) => {
  const [alignmentData, setAlignmentData] = useState({});
  const [selectedKey, setSelectedKey] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const { user } = useAuth();
  const { openSnackbar } = useContext(SnackbarContext);

  const fetchAlignmentData = async () => {
    if (!user) return;
    try {
      const response = await axios.get(routes.alignmentData, {
        headers: {
          'Authorization': `Bearer ${user?.accessToken}`,
        },
      });
      if (response.data) {
        // Transform the array into an object
        const alignmentDataObject = response.data.reduce((acc, item) => {
          acc[item.key] = item.value;
          return acc;
        }, {});

        // Assuming setAlignmentData is a function to update your context
        setAlignmentData(alignmentDataObject);
      }
    } catch (error) {
      console.error("Failed to fetch alignment data:", error);
    }
  };

  useEffect(() => {
    fetchAlignmentData();
  }, [user?.accessToken]);

  const updateSelectedKeyAndValue = (key, value) => {
    setSelectedKey(key);
    setSelectedValue(value);
  };

  const isValidVariableName = (key) => {
    const pattern = /^[a-zA-Z0-9._]+$/;
    return pattern.test(key);
  };

  // Add or update alignment data on the server
  const addOrUpdateAlignmentData = async (key, value) => {
    if (!isValidVariableName(key)) {
      openSnackbar("Error saving alignment data.", "error");
      return 'Invalid key. Only letters, numbers, periods, and underscores are allowed.';
    }

    try {
      const response = await axios.post(routes.alignmentData, { key, value }, {
        headers: {
          'Authorization': `Bearer ${user?.accessToken}`,
        },
      });
      if (response.data) {
        openSnackbar("Alignment data saved!", "success");
        // Update local state with the new/updated data
        setAlignmentData(prevData => ({ ...prevData, [key]: value }));
      }
    } catch (error) {
      console.error("Failed to add/update alignment data:", error);
      openSnackbar("Error saving alignment data.", "error");
    }
  };

  return (
    <AlignmentDataContext.Provider value={{ alignmentData, addOrUpdateAlignmentData, selectedKey, selectedValue, updateSelectedKeyAndValue }}>
      {children}
    </AlignmentDataContext.Provider>
  );
};
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { Container, Paper, TextField, Button, Typography } from '@mui/material';
import routes from 'src/config/routes';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false); // State to track if the message is an error

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsError(false); // Reset the error state on a new submission
    try {
      const response = await axios.post(routes.requestPasswordReset, { email });
      setMessage('If an account exists for this email, a password reset link has been sent.');
      // Optionally navigate the user to the login page or show a message
    } catch (error) {
      console.error('Error requesting password reset:', error);
      setMessage('An error occurred. Please try again later.');
      setIsError(true); // Set isError to true if there was an error
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper elevation={2} style={{ marginTop: '8rem', padding: '2rem' }}>
        <Typography component="h1" variant="h5">
          Forgot Password
        </Typography>
        <form onSubmit={handleSubmit} style={{ marginTop: '1rem' }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            style={{ margin: '3rem 0 2rem' }}
          >
            Request Password Reset
          </Button>
          {message && (
            <Typography style={{ color: isError ? 'red' : 'inherit' }}>
                {message}
            </Typography>
          )}
          <Link to="/login">Back to login</Link>
        </form>
      </Paper>
    </Container>
  );
};

export default ForgotPassword;
import React, { createContext, useState, useContext } from 'react';
const NavigationContext = createContext();

const isSharedAgent = window.location.pathname.startsWith('/share/agent/')

function NavigationProvider({ children }) {
  const [isSideDrawerVisible, setIsSideDrawerVisible] = useState(false);
  const [navigationPane, setNavigationPane] = useState('agentList');

  const openNavigationPane = (pane) => {
    if (!!pane) {
      setNavigationPane(pane);
    }
    if (!isSharedAgent) {
      setIsSideDrawerVisible(true);
    }
  }

  return (
    <NavigationContext.Provider value={{
      isSideDrawerVisible,
      setIsSideDrawerVisible,
      navigationPane,
      setNavigationPane,
      openNavigationPane
    }}>
      {children}
    </NavigationContext.Provider>
  );
}

function useNavigationContext() {
  const context = useContext(NavigationContext);
  if (!context) {
    throw new Error("useNavigationContext must be used within an NavigationProvider");
  }
  return context;
}

export { NavigationProvider, useNavigationContext };

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  LexicalTypeaheadMenuPlugin,
  MenuOption,
  useBasicTypeaheadTriggerMatch,
} from '@lexical/react/LexicalTypeaheadMenuPlugin';
import { useCallback, useEffect, useMemo, useState } from 'react';
import React, { useContext } from 'react';
import * as ReactDOM from 'react-dom';

import { AlignmentDataContext } from 'src/contexts/AlignmentDataContext';

import { $createMentionNode } from './MentionNode';

import './MentionNode.css'

const SUGGESTION_LIST_LENGTH_LIMIT = 8;

const mentionsCache = new Map();

// Adapted from: https://github.com/facebook/lexical/blob/main/packages/lexical-playground/src/plugins/MentionsPlugin/index.tsx

export default function NewMentionsPlugin() {
  const [editor] = useLexicalComposerContext();

  const { alignmentData } = useContext(AlignmentDataContext);

  const dummyMentionsData = Object.keys(alignmentData);

  const dummyLookupService = {
    search(string, callback) {
      const results = dummyMentionsData.filter((mention) =>
        mention.toLowerCase().includes(string.toLowerCase()),
      );
      callback(results);
    },
  };

  function useMentionLookupService(mentionString) {
    const [results, setResults] = useState([]);

    useEffect(() => {
      const cachedResults = mentionsCache.get(mentionString);

      if (mentionString == null) {
        setResults([]);
        return;
      }

      if (cachedResults === null) {
        return;
      } else if (cachedResults !== undefined) {
        setResults(cachedResults);
        return;
      }

      mentionsCache.set(mentionString, null);
      dummyLookupService.search(mentionString, (newResults) => {
        mentionsCache.set(mentionString, newResults);
        setResults(newResults);
      });
    }, [mentionString]);

    return results;
  }

  function checkForTriggers(text, minMatchLength) {
    const bracesRegex = /{{\s?([^}]*)$/;
    const doubleAngleBracketRegex = /<<([^>]*)$/;

    const bracesMatch = bracesRegex.exec(text);
    const doubleAngleBracketMatch = doubleAngleBracketRegex.exec(text);

    if (bracesMatch !== null) {
      const matchingString = bracesMatch[1];
      if (matchingString.length >= minMatchLength) {
        return {
          leadOffset: bracesMatch.index + 1,
          matchingString,
          replaceableString: bracesMatch[0],
        };
      }
    }

    if (doubleAngleBracketMatch !== null) {
      const matchingString = doubleAngleBracketMatch[1];
      if (matchingString.length >= minMatchLength) {
        return {
          leadOffset: doubleAngleBracketMatch.index + 2, // Adjust leadOffset for double <<
          matchingString,
          replaceableString: doubleAngleBracketMatch[0],
        };
      }
    }
  
    return null;
  }

  function getPossibleQueryMatch(text) {
    return checkForTriggers(text, 0);
  }

  class MentionTypeaheadOption extends MenuOption {
    constructor(name, picture) {
      super(name);
      this.name = name;
      this.picture = picture;
    }
  }

  function MentionsTypeaheadMenuItem({
    index,
    isSelected,
    onClick,
    onMouseEnter,
    option,
  }) {
    let className = 'item';
    if (isSelected) {
      className += ' selected';
    }
    return (
      <li
        key={option.key}
        tabIndex={-1}
        className={className}
        ref={option.setRefElement}
        role="option"
        aria-selected={isSelected}
        id={'typeahead-item-' + index}
        onMouseEnter={onMouseEnter}
        onClick={onClick}>
        {option.picture}
        <span className="text">{option.name}</span>
      </li>
    );
  }

  const [queryString, setQueryString] = useState(null);

  const results = useMentionLookupService(queryString);

  const checkForSlashTriggerMatch = useBasicTypeaheadTriggerMatch('/', {
    minLength: 0,
  });

  const options = useMemo(
    () =>
      results
        .map(
          (result) =>
            new MentionTypeaheadOption(result, null),
        )
        .slice(0, SUGGESTION_LIST_LENGTH_LIMIT),
    [results],
  );

  const onSelectOption = useCallback(
    (selectedOption, nodeToReplace, closeMenu) => {
      editor.update(() => {
        const textToReplace = nodeToReplace.getTextContent();
        const bracesRegexSpace = /{{\s$/;
        const doubleAngleBracketRegex = /<<$/;
  
        let newText;
        if (doubleAngleBracketRegex.test(textToReplace)) {
          const transformedText = selectedOption.name
            .toUpperCase()
            .replace(/\./g, '_');
          newText = `<${transformedText}>\n{{${selectedOption.name}}}\n</${transformedText}>`;
        } else {
          newText = '{{' + selectedOption.name + '}}';
          if (bracesRegexSpace.test(textToReplace)) {
            newText = '{{ ' + selectedOption.name + ' }}';
          }
        }
  
        const mentionNode = $createMentionNode(newText);
        if (nodeToReplace) {
          nodeToReplace.replace(mentionNode);
        }
        mentionNode.select();
        closeMenu();
      });
    },
    [editor],
  );

  const checkForMentionMatch = useCallback(
    (text) => {
      const slashMatch = checkForSlashTriggerMatch(text, editor);
      if (slashMatch !== null) {
        return null;
      }
      return getPossibleQueryMatch(text);
    },
    [checkForSlashTriggerMatch, editor],
  );

  return (
    <LexicalTypeaheadMenuPlugin
      onQueryChange={setQueryString}
      onSelectOption={onSelectOption}
      triggerFn={checkForMentionMatch}
      options={options}
      menuRenderFn={(
        anchorElementRef,
        { selectedIndex, selectOptionAndCleanUp, setHighlightedIndex },
      ) =>
        anchorElementRef.current && results.length
          ? ReactDOM.createPortal(
            <div className="typeahead-popover mentions-menu">
              <ul>
                {options.map((option, i) => (
                  <MentionsTypeaheadMenuItem
                    index={i}
                    isSelected={selectedIndex === i}
                    onClick={() => {
                      setHighlightedIndex(i);
                      selectOptionAndCleanUp(option);
                    }}
                    onMouseEnter={() => {
                      setHighlightedIndex(i);
                    }}
                    key={option.key}
                    option={option}
                  />
                ))}
              </ul>
            </div>,
            anchorElementRef.current,
          )
          : null
      }
    />
  );
}
import React, { useState, useContext, useRef } from 'react';
import { Autocomplete, Button, TextField, Typography, TextareaAutosize, Alert, Checkbox,
  FormControlLabel } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { AlignmentDataContext } from 'src/contexts/AlignmentDataContext';
import { TextFieldStyled, useStyles } from './AlignmentDataStyles';
import axios from 'axios';
import SnackbarContext from 'src/contexts/SnackbarContext';
import routes from 'src/config/routes';

function AlignmentData() {
  const [inputKey, setInputKey] = useState('');
  const [error, setError] = useState('');
  const { alignmentData, addOrUpdateAlignmentData, selectedKey, selectedValue, updateSelectedKeyAndValue } = useContext(AlignmentDataContext);
  const { openSnackbar } = useContext(SnackbarContext);

  const handleAddOrUpdateVariable = async () => {
    const keyToAddOrUpdate = selectedKey.trim();
    const result = await addOrUpdateAlignmentData(keyToAddOrUpdate, selectedValue);
    if (result === true) {
      setError('');
    } else {
      setError(result);
    }
  };

  const handleInputChange = (event, newInputValue) => {
    newInputValue = newInputValue.trim();
    setInputKey(newInputValue);
    updateSelectedKeyAndValue(newInputValue, selectedValue);
  };

  const handleBlur = () => {
    if (alignmentData.hasOwnProperty(inputKey)) {
      updateSelectedKeyAndValue(inputKey, alignmentData[inputKey]);
    }
  };

  const handleSelectVariable = (event, newValue, reason) => {
    if (reason === 'selectOption') {
      updateSelectedKeyAndValue(newValue, alignmentData[newValue]);
    }
  };

  const fileInputRef = useRef(null);
  const [useOCR, setUseOCR] = useState(false);
  const [isAppendFileDisabled, setIsAppendFileDisabled] = useState(false);
  const handleFileUpload = async () => {
    setIsAppendFileDisabled(true);
    const file = fileInputRef.current.files[0];
    if (!file) {
      setIsAppendFileDisabled(false);
      openSnackbar('Please select a file to upload.', 'error');
      return;
    }

    const formData = new FormData();
    formData.append('document', file);

    try {
      const response = await axios.post(`${routes.helpersParseDocument}?use_ocr=${useOCR}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data && response.data.text) {
        // Append the resultant text to the selectedValue
        const newSelectedValue = `${selectedValue}\n\n${response.data.text}`.trim();
        updateSelectedKeyAndValue(selectedKey, newSelectedValue);
        openSnackbar('File processed successfully.', 'success');
      } else {
        openSnackbar('Failed to process file.', 'error');
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      openSnackbar('Failed to upload file. Please try again.', 'error');
    } finally {
      fileInputRef.current.value = null;
      setIsAppendFileDisabled(false);
    }
  };

  const classes = useStyles();

  return (
    <div>
      {error && <Alert severity="error" style={{ marginBottom: '1rem' }}>{error}</Alert>}
      <Typography variant="body2" style={{ marginBottom: '1rem' }}>
        This is a place where you can set shared variables that can be used across different agents or workflows'
        "System Instructions". example variable names: my_variable, global.name, product.info.description
      </Typography>
      <Typography variant="body2" style={{ marginBottom: '1rem' }}>
        You can then reference your variables in your system instructions like: {'{{myVariable}}'}, {'{{global.name}}'}, {'{{product.info.description}}'}
        &nbsp;and it will be replaced with the value you set here.
      </Typography>
      <div style={{ marginBottom: '1rem' }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddOrUpdateVariable}
          disabled={!selectedValue || !inputKey.trim()}
          style={{ marginTop: '1rem' }}
        >
          {Object.hasOwnProperty.call(alignmentData, inputKey.trim()) ? 'Update Variable' : 'Add Variable'}
        </Button>
      </div>
      <Autocomplete
        placeholder='ex: my_variable, global.name, product.info.description'
        value={selectedKey}
        onChange={(event, newValue, reason) => handleSelectVariable(event, newValue, reason)}
        onInputChange={handleInputChange}
        inputValue={inputKey}
        options={alignmentData ? Object.keys(alignmentData) : []}
        filterOptions={(options, params) => {
          const filtered = options.filter(option =>
            option.toLowerCase().includes(params.inputValue.toLowerCase())
          );
          return filtered;
        }}
        selectOnFocus
        handleHomeEndKeys
        freeSolo
        renderInput={(params) => <TextField {...params} label="Search or add Variables" variant="outlined" onBlur={handleBlur} />}
        isOptionEqualToValue={(option, value) => option === value}
        style={{ marginBottom: '1rem' }}
      />
      <div style={{ position: 'relative', marginBottom: '1rem' }}>
        <TextFieldStyled
          variant="outlined"
          fullWidth
          minRows={3}
          maxRows={35}
          multiline
          value={selectedValue}
          onChange={(e) => updateSelectedKeyAndValue(selectedKey, e.target.value)}
          placeholder="ex: 5, 'Hello World', 'https://example.com', 'https://example.com/image.jpg', 'https://example.com/audio.mp3', 'https://example.com/video.mp4', 'https://example.com/document.pdf', 'https://example.com/spreadsheet.xlsx', 'https://example.com/presentation.pptx',"
        />
        <input
          ref={fileInputRef}
          type="file"
          style={{ display: 'none' }}
          onChange={handleFileUpload} // This will upload the file as soon as it's selected
        />
        <Button
          disabled={isAppendFileDisabled}
          variant="contained"
          color="secondary"
          onClick={() => fileInputRef.current.click()} // Opens file dialog when button is clicked
          style={{ right: '6.25rem', bottom: '-1.5rem', position: 'absolute', opacity: 0.9 }}
        >
          Upload File
        </Button>
        <FormControlLabel
          style={{ right: '-1rem', bottom: '-1rem', position: 'absolute', backgroundColor: 'rgba(255, 255, 255, .67)' }}
          control={
            <Checkbox
              checked={useOCR}
              onChange={() => setUseOCR(!useOCR)}
              name="useOCR"
              color="primary"
              style={{ padding: 0 }}
            />
          }
          label="Use OCR"
        />
      </div>
      <div>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddOrUpdateVariable}
          disabled={!selectedValue || !inputKey.trim()}
          style={{ marginTop: '1rem' }}
        >
          {Object.hasOwnProperty.call(alignmentData, inputKey.trim()) ? 'Update Variable' : 'Add Variable'}
        </Button>
      </div>
    </div>
  );
}

export default AlignmentData;
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(theme => ({
  drawerPaper: {
    padding: theme.spacing(0),
    width: theme.spacing(75),
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '85vw', // 85% of the viewport width on small screens
    },
  },
  title: {
    fontWeight: 600,
    backgroundColor: 'lightgrey',
    borderBottom: '1px solid black',
    padding: theme.spacing(1)
  },
  form: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  submitButton: {
    marginTop: 'auto',
    background: theme.palette.success.dark,
    '&:hover': {
      background: theme.palette.success.darker
    }
  },
  listItem: {
    paddingLeft: 0,
    paddingRight: 0
  },
  addAgentButton: {
    width: '.6in',
    height: '.6in',
    '& svg': {
      fontSize: '0.5in',
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    }
  },
  stickyTabs: {
    position: 'sticky',
    top: 0,
    zIndex: 1000,
    backgroundColor: theme.palette.background.paper,
  },
  stickyCloseButton: {
    position: 'sticky',
    zIndex: 1, // Higher than tabs
    backgroundColor: theme.palette.background.paper, // Ensure it doesn't overlap content
  }
}));
import React, { useState, useEffect, useContext } from 'react';
import {
  IconButton, TextField, Button, List, ListItem,
  ListItemText, Typography, Select, InputLabel, MenuItem, FormControl, Collapse, FormControlLabel, Checkbox
} from '@mui/material';

import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import AddIcon from '@mui/icons-material/Add';
import { TextFieldStyled, useStyles } from './WorkflowDetailsStyles';
import { useAgentContext } from 'src/contexts/AgentContext';
import { useWorkflowContext } from 'src/contexts/WorkflowContext';
import { useNavigationContext } from 'src/contexts/NavigationContext';
import { useAuth } from 'src/contexts/AuthContext';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import SnackbarContext from 'src/contexts/SnackbarContext';

function WorkflowDetails() {
  const {
    selectedWorkflow,
    updateWorkflow,
    selectedWorkflowRevision,
    saveWorkflow,
    handleSelectPreviousRevision,
    handleSelectNextRevision,
    selectedWorkflowRevisionIndex,
    setSelectedWorkflowRevision
  } = useWorkflowContext();
  const { openNavigationPane } = useNavigationContext();
  const { agents, setSelectedAgent, setSelectedAgentRevision } = useAgentContext();
  const { user } = useAuth();

  const classes = useStyles();

  const handleAgentChange = (index, agentId) => {
    const agentIds = [...selectedWorkflowRevision.agentIds || []];
    agentIds[index] = agentId;

    selectedWorkflowRevision.agentIds = agentIds;
    setSelectedWorkflowRevision((currentWorkflowRevision) => ({
      ...currentWorkflowRevision,
      agentIds
    }))

    // Find agent at first index and set it as the selected agent
    if (selectedWorkflowRevision.agentIds.length > 0 && selectedWorkflowRevision.agentIds[0] !== null) {
      //console.log('selectedWorkflowRevision.agentIds: ', selectedWorkflowRevision.agentIds);
      const firstAgent = agents.find(agent => agent.id === selectedWorkflowRevision.agentIds[0]);
      setSelectedAgent(firstAgent);
      //console.log('firstAgent.revisions[firstAgent.revisions.length - 1]: ', firstAgent.revisions[firstAgent.revisions.length - 1])
      setSelectedAgentRevision(firstAgent.revisions[firstAgent.revisions.length - 1]);
    }
  };

  const addAgentToWorkflow = () => {
    const agentIds = [...selectedWorkflowRevision.agentIds, null];
    setSelectedWorkflowRevision((currentWorkflowRevision) => ({
      ...currentWorkflowRevision,
      agentIds
    }))
  };

  const handleUpdateWorkflow = (event, property) => {
    let value = event.target.value;
    const newWorkflowRevision = { ...selectedWorkflowRevision, [property]: value };
    const updatedWorkflowRevision = {
      ...newWorkflowRevision
    };
    updateWorkflow(updatedWorkflowRevision);
  };

  const handleSaveWorkflow = () => {
    saveWorkflow(selectedWorkflow, selectedWorkflowRevision);
  };

  const { openSnackbar } = useContext(SnackbarContext);

  const [isShowShareLinkChecked, setShowShareLinkChecked] = useState(false);
  const [isPublishExpanded, setPublishExpanded] = useState(false);

  return (
    <div>
      <IconButton
        onClick={() => { openNavigationPane('workflowList') }}
      >
        <ArrowLeftIcon />
      </IconButton>

      Back
      <Typography variant="h6" className={classes.title}>
        Workflow: {selectedWorkflowRevision.name}
      </Typography>
      <div className={classes.revisionNavigation}>
        <IconButton
          onClick={handleSelectPreviousRevision}
          disabled={selectedWorkflowRevisionIndex === 0}
        >
          <ArrowLeftIcon />
        </IconButton>
        <Typography variant="body1">
          Revision #{selectedWorkflowRevision.id}
        </Typography>
        <IconButton
          onClick={handleSelectNextRevision}
          disabled={selectedWorkflowRevisionIndex === selectedWorkflow.revisions.length - 1}
        >
          <ArrowRightIcon />
        </IconButton>
      </div>
      <div className="workflow-side-drawer">
        <TextField
          label="Workflow Name"
          variant="outlined"
          value={selectedWorkflowRevision.name}
          onChange={(event) => handleUpdateWorkflow(event, 'name')}
          onKeyPress={(event) => event.key === 'Enter' && handleUpdateWorkflow(event, 'name')}
          fullWidth
        />

        <Typography variant="h5" gutterBottom>
          Workflow Details
        </Typography>

        <Typography variant="body1" className={classes.workflowDescription}>
          {`Workflows allow you to chain agents in sequence. An agent terminates and advances when JSON
          { "agent700Terminate": true, ..."customKey": customData... } is in a response, then passes the JSON to the next agent.`}
        </Typography>
        <br />

        {selectedWorkflowRevision.agentIds.map((agentId, index) => (
          <FormControl key={index} fullWidth className={classes.formControl}>
            <InputLabel id={`agent-select-label-${index}`}>Select Agent</InputLabel>
            <Select
              labelId={`agent-select-label-${index}`}
              id={`agent-select-${index}`}
              value={agentId}
              onChange={(e) => handleAgentChange(index, e.target.value)}
            >
              {agents.map((agent) => (
                <MenuItem key={agent.id} value={agent.id}>
                  {agent.revisions[agent.revisions.length - 1].name}
                </MenuItem>
              ))}
            </Select>
            <br />
          </FormControl>
        ))}
        <IconButton onClick={addAgentToWorkflow} className={classes.addButton}>
          <AddIcon />
        </IconButton>

        {/*
        <ListItem
            button
            className={`${classes.listItem} ${classes.grayBackground}`}
            onClick={() => setPublishExpanded(!isPublishExpanded)}
          >
            <ListItemText primary="Publish" />
            {isPublishExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItem>
        <Collapse in={isPublishExpanded} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isShowShareLinkChecked}
                    onChange={() => {
                      setShowShareLinkChecked(!isShowShareLinkChecked);
                    }}
                  />
                }
                label="Generate Share Link"
              />
              {isShowShareLinkChecked && (
                <div>
                  <a href={`${process.env.REACT_APP_HOST}/share/workflow/${selectedWorkflow.id}/revision/${selectedWorkflowRevision.id}`}>
                    {`${process.env.REACT_APP_HOST}/share/workflow/${selectedWorkflow.id}/revision/${selectedWorkflowRevision.id}`}
                  </a>
                  <Button
                    variant="contained" color="primary" size="small" style={{ marginLeft: '1rem' }} onClick={() => {
                      openSnackbar('Link copied to clipboard');
                      navigator.clipboard.writeText(`${process.env.REACT_APP_HOST}/share/workflow/${selectedWorkflow.id}/revision/${selectedWorkflowRevision.id}`);
                    }}
                  >
                    Copy
                  </Button>
                </div>
              )}
            </ListItem>
          </List>
        </Collapse>
                  */}

        <div className={classes.saveButtonContainer}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveWorkflow}
            className={classes.saveButton}
          >
            Save
          </Button>
        </div>

      </div>
    </div>
  );
}

export default WorkflowDetails;

import React, { useState, useContext, useEffect, useCallback, useRef } from 'react';
import {
  Drawer, IconButton, TextField, TextareaAutosize, Button, List, ListItem,
  ListItemText, ListItemIcon, Typography, Select, InputLabel, MenuItem, Checkbox,
  FormControlLabel, Collapse, useMediaQuery
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useTheme } from '@mui/material/styles';
import { Autocomplete } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import GroupIcon from '@mui/icons-material/Group';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
//import AgentPromptChain from './AgentPromptChain';
import { TextFieldStyled, useStyles } from './AgentDetailsStyles';
import { useAgentContext } from 'src/contexts/AgentContext';
import { useNavigationContext } from 'src/contexts/NavigationContext';
import { useOrganizationContext } from 'src/contexts/OrganizationContext';
import { useAuth } from 'src/contexts/AuthContext';
import SnackbarContext from 'src/contexts/SnackbarContext';
import AlignmentDataAutocompleteEditor from './AlignmentDataAutocompleteEditor/AlignmentDataAutocompleteEditor'

import { useNavigate } from 'react-router-dom';
import { useQASheetContext } from 'src/contexts/QASheetContext';
import routes from 'src/config/routes';
import axios from 'axios';
import FileSaver from 'file-saver';
import { debounce, set, isNaN } from 'lodash';

import { v4 as uuid } from 'uuid';

import { hasAgentRevisionChanged } from 'src/components/utils';


function AgentDetails() {
  const {
    selectedAgent,
    setSelectedAgentRevision,
    selectedAgentRevision,
    saveAgentRevision,
    handleSelectPreviousRevision,
    handleSelectNextRevision,
    selectedAgentRevisionIndex,
    lastSavedAgentRevisions
  } = useAgentContext();
  const { openNavigationPane } = useNavigationContext();
  const { user } = useAuth();
  //console.log('user', user);

  const [isPromptChainExpanded, setIsPromptChainExpanded] = useState(false);
  const [tempAgentRevision, setTempAgentRevision] = useState({ ...selectedAgentRevision });

  const [isPublishExpanded, setPublishExpanded] = useState(false);
  const [isAdvancedSettingsExpanded, setAdvancedSettingsExpanded] = useState(false);
  const [isTestSuitesExpanded, setTestSuitesExpanded] = useState(false);

  const [isInternalSharingExpanded, setIsInternalSharingExpanded] = useState(false);
  const [internalShareEmails, setInternalShareEmails] = useState([]);
  const [sharedOrganizationIds, setSharedOrganizationIds] = useState([]);

  const [sharedUserEmails, setSharedUserEmails] = useState([]);

  const { openSnackbar } = useContext(SnackbarContext);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const navigate = useNavigate();
  const { fetchQASheetForAgentCallback } = useQASheetContext();
  const { members } = useOrganizationContext();

  const [autocompleteComponentKey, setAutocompleteComponentKey] = useState(0);


  const fileInputRef = useRef(null);
  const [useOCR, setUseOCR] = useState(false);
  const [isAppendFileDisabled, setIsAppendFileDisabled] = useState(false);

  const togglePromptChain = () => {
    setIsPromptChainExpanded(prevState => !prevState);
  };

  const classes = useStyles();

  const handleSaveAgent = () => {
    if (!selectedAgent.sharedWithUser) {
      saveAgentRevision(selectedAgent, tempAgentRevision);
    }
  };

  const handleNavigateToQASheet = () => {
    fetchQASheetForAgentCallback(selectedAgent.id);
    navigate(`/agent/${selectedAgent.id}/qasheet`);
  };

  const handleOrganizationShareChange = (event, newValue) => {
    setSharedOrganizationIds(newValue.map(option => option.id));
  };

  const handleInternalShare = async () => {
    try {
      const response = await axios.post(`${routes.agents}/${selectedAgent.id}/share`, {
        sharedUserEmails: internalShareEmails,  // Send array of emails
        sharedOrganizationIds: sharedOrganizationIds,  // Send array of organization IDs
      });
      if (response.data.status === 'success') {
        openSnackbar('Agent sharing updated successfully', 'success');
      }
    } catch (error) {
      console.error('Error updating agent sharing:', error);
      openSnackbar('Failed to update agent sharing. Please try again.', 'error');
    }
  };

  const handleRatingsExportClick = () => {
    axios.get(routes.ratingsExport,
      { responseType: 'blob' })
      .then((response) => {
        const blob = new Blob([response.data],
          { type: 'text/csv' });
        FileSaver.saveAs(blob, 'ratings.csv');
      });
  };


  const handleFileUpload = async () => {
    setIsAppendFileDisabled(true);
    const file = fileInputRef.current.files[0];
    if (!file) {
      setIsAppendFileDisabled(false);
      openSnackbar('Please select a file to upload.', 'error');
      return;
    }

    const formData = new FormData();
    formData.append('document', file);

    try {
      const response = await axios.post(`${routes.helpersParseDocument}?use_ocr=${useOCR}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data && response.data.text) {
        // Append the resultant text to the system instructions
        const newMasterPrompt = `${tempAgentRevision.masterPrompt}\n\n${response.data.text}`.trim();
        updateAgentRevisionProperty('masterPrompt', newMasterPrompt);
        openSnackbar('File processed successfully.', 'success');
      } else {
        openSnackbar('Failed to process file.', 'error');
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      openSnackbar('Failed to upload file. Please try again.', 'error');
    } finally {
      fileInputRef.current.value = null;
      setIsAppendFileDisabled(false);
    }
  };

  //console.log('Revision index properties: ', selectedAgentRevisionIndex, selectedAgent.revisions.length - 1);


  // Check if the user's a part of the Anthem organization (id: 1b5517c6-de22-4f96-aacf-7f28f3d7d5dc)
  // or Level Up organization (id: 90680a57-4613-400b-a32f-f80b236fc300)
  // If so, show the Anthem Custom Model option
  const canSeeAnthemModel = user && user.organizationsRoles && user.organizationsRoles.some((org) => (
    org.id === '1b5517c6-de22-4f96-aacf-7f28f3d7d5dc' || org.id === '90680a57-4613-400b-a32f-f80b236fc300'
  ));

  // Check if the user's a part of the Pureinsights organization (id: 050780ae-a0d8-45b5-9bd6-2c1e87e357bc)
  // or Level Up organization (id: 90680a57-4613-400b-a32f-f80b236fc300)
  // If so, show the Pureinsights Custom Model option
  const canSeePureinsightsModel = user && user.organizationsRoles && user.organizationsRoles.some((org) => (
    org.id === '050780ae-a0d8-45b5-9bd6-2c1e87e357bc' || org.id === '90680a57-4613-400b-a32f-f80b236fc300'
  ));

  const handleUpdateAgentRevisionProperty = (event, property, allowBlank = true) => {
    let value = event.target.value;
    const min = 0;
    const floatProperties = ['temperature', 'topP', 'piiThreshold'];
    const booleanProperties = ['scrubPii', 'streamResponses', 'allowPublicSharing', 'smartDocEvaluation', 'hasPhoneNumber'];

    // If value < min or value > max, set value to closest limit
    const limitValue = (value, min, max) => {
      let parsedValue = value;
      if (!parsedValue && parsedValue !== 0) {
        if (allowBlank) {
          return '';
        } else {
          parsedValue = 0;
        }
      }
      return Math.max(min, Math.min(max, parsedValue));
    };

    if (floatProperties.includes(property)) {
      value = parseFloat(value);
      const max = property === 'temperature' ? 2 : 1;
      value = limitValue(value, min, max);
    }

    if (property === 'maxTokens') {
      value = parseInt(value, 10);
      const max = 4096;
      value = limitValue(value, min, max);
    }

    if (booleanProperties.includes(property)) {
      value = event.target.checked;
    }

    if (property === 'customShareUrl') {
      value = value.replace(/[^a-zA-Z0-9-]/g, '-');
    }

    updateAgentRevisionProperty(property, value);
  };

  const updateAgentRevisionProperty = (property, value) => {
    setTempAgentRevision(prevState => ({
      ...prevState,
      [property]: value,
    }));
    debouncedUpdateSelectedAgentProperty(property, value);
  }

  const getUniqueOrganizations = () => {
    const organizations = [];
    const organizationMap = new Map();

    if (!members) {
      return []
    }

    for (const member of members) {
      if (!organizationMap.has(member.organizationId)) {
        organizationMap.set(member.organizationId, true); // set any value to Map
        organizations.push({
          id: member.organizationId,
          name: member.organization,
        });
      }
    }

    return organizations;
  };

  const handleSelectAllMembers = (organization) => {
    const organizationId = members.find(member => member.organization === organization)?.organizationId;
    if (!organizationId) return;

    setInternalShareEmails((prevEmails) => {
      const organizationEmails = members
        .filter(member => member.organization === organization)
        .map(member => member.email);
      const allSelected = organizationEmails.every(email => prevEmails.includes(email));
      if (allSelected) {
        // Deselect all members of this organization
        return prevEmails.filter(email => !organizationEmails.includes(email));
      } else {
        // Select all members of this organization
        return [...new Set([...prevEmails, ...organizationEmails])];
      }
    });
  };

  const debouncedUpdateSelectedAgentProperty = useCallback(debounce((property, value) => {
    setSelectedAgentRevision((prevState) => {
      return { ...tempAgentRevision, [property]: value }
    });
  }, 500), [tempAgentRevision.id, selectedAgentRevision]);

  // New agent selected or persisted?
  useEffect(() => {
    setTempAgentRevision((prevState) => {
      return (tempAgentRevision.agentId === selectedAgentRevision.agentId && tempAgentRevision.id === selectedAgentRevision.id) ? tempAgentRevision : { ...selectedAgentRevision };
    });
  }, [selectedAgentRevision]);

  // For when revision # changes
  useEffect(() => {
    // Set autocomplete key to a random vaalue to force a re-render of the component
    setAutocompleteComponentKey(uuid());
  }, [selectedAgentRevision.id]);

  useEffect(() => {
    const fetchSharingInfo = async () => {
      try {
        const response = await axios.get(`${routes.agents}/${selectedAgent.id}/sharing-info`);
        setSharedUserEmails(response.data.emails);
        setInternalShareEmails(response.data.emails);  // Initialize with shared users
        setSharedOrganizationIds(response.data.organizationIds);
      } catch (error) {
        console.error('Error fetching sharing info:', error);
        // openSnackbar('Failed to fetch sharing info. Please try again.', 'error');
      }
    };

    if (selectedAgent.id) {
      fetchSharingInfo();
    }
  }, [selectedAgent.id, openSnackbar]);

  const shareUrl = !!tempAgentRevision.customShareUrl
    ? `${process.env.REACT_APP_HOST}/share/agent/${selectedAgent.id}/${tempAgentRevision.customShareUrl}`
    : `${process.env.REACT_APP_HOST}/share/agent/${selectedAgent.id}`;

  const lastSavedRevision = lastSavedAgentRevisions[tempAgentRevision.agentId] || {};

  let alignmentInstructionsPlaceholder = 'ex: Act as a Project Manager with expertise in Jira, Scrum, and Agile Methodologies.';
  alignmentInstructionsPlaceholder += !isMobile ? ' Examine provided user stories and provide a description, test criteria, sub-tasks and estimates in story points.' : '';

  const organizationOptions = getUniqueOrganizations();

  return (
    <div>
      <div className={classes.saveButtonContainer}>
        {/* Only allow agent to be saved if there are changes to the revision */}
        <Button
          disabled={!hasAgentRevisionChanged(lastSavedRevision, tempAgentRevision) || selectedAgent.sharedWithUser}
          variant="contained"
          color="primary"
          onClick={handleSaveAgent}
          className={classes.saveButton}
        >
          Save
        </Button>
      </div>

      <IconButton
        onClick={() => {
          if (hasAgentRevisionChanged(lastSavedRevision, tempAgentRevision) && !selectedAgent.isShared) {
            handleSaveAgent();
          }
          setTimeout(() => { openNavigationPane('agentList') }, 0);
        }}
      >
        <ArrowLeftIcon />
      </IconButton>

      Back
      <Typography variant="h6" className={classes.title}>
        Agent: {tempAgentRevision.name}
      </Typography>
     {selectedAgent.sharedWithUser ? <>
            <ListItem
              className={`${classes.listItem}`}
            >
              <ListItemIcon>
                <GroupIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <>
                    <span style={{fontWeight: 'bold'}}>Shared By: </span>
                    <span>{`${selectedAgent.ownerEmail}`}</span>
                  </>
                }
              />
            </ListItem>
          </> : null}
      <div className={classes.revisionNavigation}>
        <IconButton
          onClick={handleSelectPreviousRevision}
          disabled={selectedAgentRevisionIndex === 0}
        >
          <ArrowLeftIcon />
        </IconButton>
        <Typography variant="body1">
          Revision #{tempAgentRevision.id}
        </Typography>
        <IconButton
          onClick={handleSelectNextRevision}
          disabled={selectedAgentRevisionIndex === selectedAgent.revisions.length - 1}
        >
          <ArrowRightIcon />
        </IconButton>
      </div>
      <div className="agent-side-drawer">
        <TextField
          label="Agent Name"
          variant="outlined"
          value={tempAgentRevision.name}
          onChange={(event) => handleUpdateAgentRevisionProperty(event, 'name')}
          onKeyPress={(event) => event.key === 'Enter' && handleUpdateAgentRevisionProperty(event, 'name')}
          disabled={selectedAgent.sharedWithUser}
          fullWidth
        />
        {/* Material UI select list containing the AI models that can be seleted from, including:
        GPT-4, GPT-3.5-turbo, and Claude 2.0
        */}
        <Select
          variant="outlined"
          fullWidth
          defaultValue={'gpt-4'}
          value={tempAgentRevision.model || 'gpt-4'}
          onChange={(event) => handleUpdateAgentRevisionProperty(event, 'model')}
          style={{ marginTop: '1rem', marginBottom: '1rem' }}
          disabled={selectedAgent.sharedWithUser}
        >
          {/*{canSeePureinsightsModel && <MenuItem value="pureinsights-model">Pureinsights LLM</MenuItem>}*/}
          {canSeeAnthemModel && <MenuItem value="anthem-v1">Anthem v1</MenuItem>}
          {canSeeAnthemModel && <MenuItem value="anthem-model">Anthem Open Enrollment</MenuItem>}
          <MenuItem value="gpt-4o">GPT-4o</MenuItem>
          <MenuItem value="gpt-4o-mini">GPT-4o Mini</MenuItem>
          <MenuItem value="gpt-4-turbo-preview">GPT-4 Turbo (Preview)</MenuItem>
          <MenuItem value="gemini-1.5-pro-latest">Google Gemini Pro 1.5</MenuItem>
          <MenuItem value="gemini-1.5-flash">Google Gemini Flash</MenuItem>
          {/* <MenuItem value="claude-3-opus-20240229">Claude 3 Opus (Large)</MenuItem> */}
          <MenuItem value="claude-3-5-sonnet-20240620">Claude 3.5 Sonnet</MenuItem>
          {/* <MenuItem value="claude-3-haiku-20240307">Claude 3 Haiku (Small)</MenuItem> */}
          <MenuItem value="claude-instant-1.2">Claude 1.2 (Instant)</MenuItem>
          <MenuItem value="gpt-3.5-turbo">GPT-3.5 Turbo</MenuItem>
          <MenuItem value="gpt-4">GPT-4</MenuItem>
          <MenuItem value="open-mistral-7b">Mistral 7B</MenuItem>
          <MenuItem value="open-mixtral-8x7b">Mixtral 8x7B</MenuItem>
          <MenuItem value="open-mistral-nemo">Mistral Nemo</MenuItem>
          <MenuItem value="mistral-small-latest">Mistral Small</MenuItem>
          <MenuItem value="mistral-medium-latest">Mistral Medium</MenuItem>
          <MenuItem value="mistral-large-2407">Mistral Large 2</MenuItem>
          {/* <MenuItem value="llama-2-7b-hf">Llama 2 (7b) (HF)</MenuItem>
          <MenuItem value="img-dall-e-2">dall-e-2 (Image Generation)</MenuItem>
          <MenuItem value="img-dall-e-3">dall-e-3 (Image Generation)</MenuItem> */}
        </Select>
        {tempAgentRevision.model === 'img-dall-e-2' && <>
          <Select
            variant="outlined"
            fullWidth
            defaultValue={'256x256'}
            value={tempAgentRevision.imageDimensions || '256x256'}
            onChange={(event) => handleUpdateAgentRevisionProperty(event, 'imageDimensions')}
            style={{ marginTop: '1rem', marginBottom: '1rem' }}
            disabled={selectedAgent.sharedWithUser}
          >
            <MenuItem value="256x256">256x256</MenuItem>
            <MenuItem value="512x512">512x512</MenuItem>
            <MenuItem value="1024x1024">1024x1024</MenuItem>
          </Select>
        </>}
        {tempAgentRevision.model === 'img-dall-e-3' && <>
          <Select
            variant="outlined"
            fullWidth
            defaultValue={'1024x1024'}
            value={tempAgentRevision.imageDimensions || '1024x1024'}
            onChange={(event) => handleUpdateAgentRevisionProperty(event, 'imageDimensions')}
            style={{ marginTop: '1rem', marginBottom: '1rem' }}
            disabled={selectedAgent.sharedWithUser}
          >
            <MenuItem value="1024x1024">1024x1024</MenuItem>
            <MenuItem value="1792x1024">1792x1024</MenuItem>
            <MenuItem value="1024x1792">1024x1792</MenuItem>
          </Select>
        </>}
        { /* Anthem sees none of the additional options */}
        {user && tempAgentRevision.model !== 'anthem-model' && tempAgentRevision.model !== 'anthem-v1' && <>
          <div style={{ position: 'relative', marginBottom: '1rem' }}>
            <AlignmentDataAutocompleteEditor
              key={`alignment-data-autocomplete-editor-${autocompleteComponentKey}`}
              onChange={(text) => handleUpdateAgentRevisionProperty({ target: { value: text } }, 'masterPrompt')}
              inputText={tempAgentRevision.masterPrompt}
              placeholder={alignmentInstructionsPlaceholder}
              label={'System Instructions (AI Persona)'}
              minLines={3.5}
              editable={!selectedAgent.sharedWithUser}
            />
            <input
              ref={fileInputRef}
              type="file"
              style={{ display: 'none' }}
              onChange={handleFileUpload} // This will upload the file as soon as it's selected
            />
            <Button
              disabled={isAppendFileDisabled || selectedAgent.sharedWithUser}
              variant="contained"
              color="secondary"
              onClick={() => fileInputRef.current.click()} // Opens file dialog when button is clicked
              style={{ right: '6.25rem', bottom: '-1.5em', position: 'absolute', opacity: 0.9 }}
            >
              Upload File
            </Button>
            <FormControlLabel
              style={{ right: '-1rem', bottom: '-1rem', position: 'absolute', backgroundColor: 'rgba(255, 255, 255, .67)' }}
              control={
                <Checkbox
                  checked={useOCR}
                  onChange={() => setUseOCR(!useOCR)}
                  disabled={selectedAgent.sharedWithUser}
                  name="useOCR"
                  color="primary"
                  style={{ padding: 0 }}
                />
              }
              label="Use OCR"
            />
          </div>

          { /* Pureinsights only sees the system instructions */}
          {tempAgentRevision.model !== 'pureinsights-model' && <>
            {(tempAgentRevision.model === 'claude-2.0'
              || tempAgentRevision.model === 'claude-2.1'
              || tempAgentRevision.model === 'claude-3-opus-20240229'
              || tempAgentRevision.model === 'claude-3-sonnet-20240229'
              || tempAgentRevision.model === 'claude-3-haiku-20240307'
              || tempAgentRevision.model === 'claude-instant-1.2'
              || tempAgentRevision.model === 'gemini-1.0-pro'
              || tempAgentRevision.model === 'gemini-1.5-pro-latest'
              || tempAgentRevision.model === 'open-mistral-7b'
              || tempAgentRevision.model === 'open-mixtral-8x7b'
              || tempAgentRevision.model === 'mistral-small-latest'
              || tempAgentRevision.model === 'mistral-medium-latest'
              || tempAgentRevision.model === 'mistral-large-latest') && <Typography variant="body2">
                NOTE: Some LLMs require that the first message be from a human. We ignore assistant messages when such models are selected.
              </Typography>}
            <TextFieldStyled
              label="Introductory Remarks"
              variant="outlined"
              fullWidth
              placeholder={`Example: Hello, how can I assist you today?`}
              value={tempAgentRevision.introductoryText}
              onChange={(event) => handleUpdateAgentRevisionProperty(event, 'introductoryText')}
              multiline
              minRows={3}
              maxRows={15}
              disabled={selectedAgent.sharedWithUser}
            />
          </>}
        </>}
        {!selectedAgent.sharedWithUser ?
          <>
            <ListItem
              button
              className={`${classes.listItem} ${classes.grayBackground}`}
              onClick={() => setIsInternalSharingExpanded(!isInternalSharingExpanded)}
            >
              <ListItemText primary="Internal Sharing" />
              {isInternalSharingExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItem>
            <Collapse in={isInternalSharingExpanded} timeout="auto" unmountOnExit>
              <List component="div">
                <ListItem className={classes.nested}>
                  <Autocomplete
                    multiple
                    disableCloseOnSelect
                    options={members}
                    groupBy={(option) => option.organization}
                    getOptionLabel={(option) => option.email}
                    value={members?.filter(member => internalShareEmails.includes(member.email))}
                    limitTags={3}
                    disabled={selectedAgent.sharedWithUser}
                    onChange={(event, newValue) => {
                      setInternalShareEmails(newValue.map(option => option.email));
                    }}
                    renderOption={(props, option, { selected }) => {
                      return (
                        <li {...props}>
                          <Checkbox
                            icon={selected ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.email}
                        </li>
                      );
                    }}
                    renderGroup={(params) => {
                      return (
                        <div key={params.key}>
                          <div onClick={() => handleSelectAllMembers(params.group)}>
                            <Checkbox
                              icon={<CheckBoxOutlineBlankIcon />}
                              checkedIcon={<CheckBoxIcon />}
                              checked={members
                                .filter(member => member.organization === params.group)
                                .every(member => internalShareEmails.includes(member.email))}
                              indeterminate={members
                                .filter(member => member.organization === params.group)
                                .some(member => internalShareEmails.includes(member.email)) && !members
                                  .filter(member => member.organization === params.group)
                                  .every(member => internalShareEmails.includes(member.email))}
                            />
                            <strong>{params.group}</strong>
                          </div>
                          {params.children}
                        </div>
                      )
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Share with Users (Email)"
                        placeholder="Enter user emails"
                        variant="outlined"
                        fullWidth
                        style={{ marginTop: '1rem' }}
                      />
                    )}
                    sx={{ width: '100%' }}
                  />
                </ListItem>
                <ListItem className={classes.nested}>
                  <Autocomplete
                    multiple
                    disableCloseOnSelect
                    options={organizationOptions}
                    getOptionLabel={(option) => option.name}
                    value={organizationOptions.filter(org => sharedOrganizationIds.includes(org.id))}
                    onChange={handleOrganizationShareChange}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={selected ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                          style={{ marginRight: 8 }}
                          checked={sharedOrganizationIds.includes(option.id)} // Check if the organization is selected
                        />
                        {option.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Share with Organizations"
                        placeholder="Enter organization names"
                        variant="outlined"
                        fullWidth
                        style={{ marginTop: '1rem' }}
                      />
                    )}
                    sx={{ width: '100%' }}
                  />
                </ListItem>
                <ListItem>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ marginLeft: '1rem', marginTop: '1rem' }}
                    onClick={handleInternalShare}
                  >
                    Share
                  </Button>
                </ListItem>
              </List>
            </Collapse>
          </> : null}

        <ListItem
          button
          className={`${classes.listItem} ${classes.grayBackground}`}
          onClick={() => setAdvancedSettingsExpanded(!isAdvancedSettingsExpanded)}
        >
          <ListItemText primary="Advanced Settings" />
          {isAdvancedSettingsExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
        <Collapse in={isAdvancedSettingsExpanded} timeout="auto" unmountOnExit>

          {!tempAgentRevision?.model.startsWith('img') && <>
            <TextField
              label="Temperature (0.0 - 2.0)"
              type="number"
              variant="outlined"
              value={tempAgentRevision.temperature}
              InputProps={{
                inputProps: { min: 0, max: 2, step: 0.1 },
              }}
              onChange={(event) => handleUpdateAgentRevisionProperty(event, 'temperature')}
              onBlur={(event) => handleUpdateAgentRevisionProperty(event, 'temperature', false)}
              fullWidth
              style={{ marginTop: '1rem' }}
              disabled={selectedAgent.sharedWithUser}
            />

            <TextField
              label="top_p (0.0 - 1.0)"
              type="number"
              variant="outlined"
              value={tempAgentRevision.topP}
              InputProps={{
                inputProps: { min: 0, max: 1, step: 0.1 },
              }}
              onChange={(event) => handleUpdateAgentRevisionProperty(event, 'topP')}
              onBlur={(event) => handleUpdateAgentRevisionProperty(event, 'topP', false)}
              fullWidth
              style={{ marginTop: '1rem', marginBottom: '1rem' }}
              disabled={selectedAgent.sharedWithUser}
            />

            <TextField
              label="max response tokens (0 - 4096)"
              type="number"
              variant="outlined"
              value={tempAgentRevision.maxTokens}
              InputProps={{ inputProps: { min: 0, max: 4096 } }}
              onChange={(event) => handleUpdateAgentRevisionProperty(event, 'maxTokens')}
              onBlur={(event) => handleUpdateAgentRevisionProperty(event, 'maxTokens', false)}
              fullWidth
              style={{ marginTop: '1rem', marginBottom: '1rem' }}
              disabled={selectedAgent.sharedWithUser}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={tempAgentRevision.scrubPii}
                  onChange={(event) => handleUpdateAgentRevisionProperty(event, 'scrubPii')}
                  name="scrubPii"
                  disabled={selectedAgent.sharedWithUser}
                />
              }
              label="Scrub PII"
            />
            <Typography variant="body2">
              Between 0.0 - 1.0. Lower is more sensitive.
            </Typography>
            <TextField
              label="PII Threshold"
              type="number"
              InputProps={{
                inputProps: { min: 0, max: 1, step: 0.01 },
              }}
              variant="outlined"
              value={tempAgentRevision.piiThreshold}
              onChange={(event) => handleUpdateAgentRevisionProperty(event, 'piiThreshold')}
              onBlur={(event) => handleUpdateAgentRevisionProperty(event, 'piiThreshold', false)}
              fullWidth
              style={{ marginTop: '1rem', marginBottom: '1rem' }}
              disabled={selectedAgent.sharedWithUser}
            />

            <Typography variant="body2">
              Better, faster results for large document or Data Library queries, at 1/100th the cost.
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={tempAgentRevision.smartDocEvaluation}
                  onChange={(event) => handleUpdateAgentRevisionProperty(event, 'smartDocEvaluation')}
                  name="smartDocEvaluation"
                  disabled={selectedAgent.sharedWithUser}
                />
              }
              label="Smart Document Evaluation"
            />

            <Typography variant="body2">
              Streaming the preferred way to use the platform as a user. It's recommended to keep this enabled.
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={tempAgentRevision.streamResponses}
                  onChange={(event) => {
                    handleUpdateAgentRevisionProperty(event, 'streamResponses')
                  }}
                  disabled={selectedAgent.sharedWithUser}
                />
              }
              label="Stream Responses (if supported)"
            />

            {/* {tempAgentRevision.smartDocEvaluation && (
                  <div>
                    <Typography variant="body2">
                      Changing Smart Document settings will rebuild Evaluation for each document in the Data Library, which may cause queries to run slow at first.
                    </Typography>
                    <TextField
                      label="Chunk Size"
                      type="number"
                      variant="outlined"
                      value={tempAgentRevision.smartDocChunkSize || ''}
                      onChange={(event) => handleUpdateAgentRevisionProperty(event, 'smartDocChunkSize')}
                      fullWidth
                      style={{ marginTop: '1rem', marginBottom: '1rem' }}
                    />

                    <TextField
                      label="Chunk Overlap"
                      type="number"
                      variant="outlined"
                      value={tempAgentRevision.smartDocChunkOverlap || ''}
                      onChange={(event) => handleUpdateAgentRevisionProperty(event, 'smartDocChunkOverlap')}
                      fullWidth
                      style={{ marginTop: '1rem', marginBottom: '1rem' }}
                    />

                    <Select
                      variant="outlined"
                      fullWidth
                      defaultValue={'all-MiniLM-L6-v2'}
                      value={tempAgentRevision.smartDocEmbeddingModel || 'all-MiniLM-L6-v2'}
                      onChange={(event) => handleUpdateAgentRevisionProperty(event, 'smartDocEmbeddingModel')}
                      style={{ marginTop: '1rem', marginBottom: '1rem' }}
                    >
                      <MenuItem value="all-MiniLM-L6-v2">all-MiniLM-L6-v2 (fast, good)</MenuItem>
                      <MenuItem value="all-mpnet-base-v2">all-mpnet-base-v2 (slower, best)</MenuItem>
                    </Select>

                    <TextField
                      label="Top-k (# matches)"
                      type="number"
                      variant="outlined"
                      value={tempAgentRevision.smartDocTopK || ''}
                      onChange={(event) => handleUpdateAgentRevisionProperty(event, 'smartDocTopK')}
                      fullWidth
                      style={{ marginTop: '1rem', marginBottom: '1rem' }}
                    />
                  </div>
                )} */}
          </>}

        </Collapse>


        <List>
          {/*<ListItem button className={classes.listItem} onClick={togglePromptChain}>
            <ListItemText primary="Prompt Chain (WIP)" />
            {isPromptChainExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItem>
        {isPromptChainExpanded && <AgentPromptChain />}*/}
          <ListItem
            button
            className={`${classes.listItem} ${classes.grayBackground}`}
            onClick={() => setTestSuitesExpanded(!isTestSuitesExpanded)} // You will need to define `isTestSuitesExpanded` in your component state
          >
            <ListItemText primary="Test Suites" />
            {isTestSuitesExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItem>
          <Collapse in={isTestSuitesExpanded} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {/* <ListItem button className={classes.nested} onClick={() => { return; }}>
                <ListItemText primary="Q&A Sheet" />
              </ListItem>
              <ListItem button className={classes.nested}>
                <ListItemText primary="Test Runs (Coming Soon)" />
              </ListItem> */}
              <ListItem button className={classes.nested} disabled={selectedAgent.sharedWithUser} onClick={handleRatingsExportClick}>
                <ListItemText primary="Ratings Export" />
              </ListItem>
            </List>
          </Collapse>

          <ListItem
            button
            className={`${classes.listItem} ${classes.grayBackground}`}
            onClick={() => setPublishExpanded(!isPublishExpanded)}
          >
            <ListItemText primary="Publish" />
            {isPublishExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItem>
          <Collapse in={isPublishExpanded} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={tempAgentRevision.allowPublicSharing}
                      onChange={(event) => handleUpdateAgentRevisionProperty(event, 'allowPublicSharing')}
                      disabled={selectedAgent.sharedWithUser}
                    />
                  }
                  label="Public Share Link?"
                />
                {tempAgentRevision.allowPublicSharing && (
                  <div>
                    <Typography variant="body2">
                      Link will not be accessible until you "Save" the agent. WARNING: Saving with this option disabled will DEACTIVATE all of your publicly shared links.
                    </Typography>
                    <a href={shareUrl}>
                      {shareUrl}
                    </a>
                    {/* Custom URL input box */}
                    <TextField
                      label="Custom Share Page URL"
                      placeholder='ex: my-agent-name'
                      variant="outlined"
                      fullWidth
                      value={tempAgentRevision.customShareUrl || ''}
                      onChange={(event) => handleUpdateAgentRevisionProperty(event, 'customShareUrl')}
                      style={{ marginTop: '1rem' }}
                      disabled={selectedAgent.sharedWithUser}
                    />
                    {/* Button to copy link into clipboard */}
                    <Button
                      variant="contained" color="primary" size="small" style={{ marginLeft: '1rem' }} onClick={() => {
                        openSnackbar('Link copied to clipboard');
                        navigator.clipboard.writeText(shareUrl);
                      }}
                      disabled={selectedAgent.sharedWithUser}
                    >
                      Copy
                    </Button>
                  </div>
                )}
              </ListItem>



              {/* <ListItem button className={classes.nested}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={tempAgentRevision.hasPhoneNumber}
                      onChange={(event) => handleUpdateAgentRevisionProperty(event, 'hasPhoneNumber')}
                    />
                  }
                  label="Has Phone Number?"
                />
                {tempAgentRevision.hasPhoneNumber && (
                  <div>
                    <Typography variant="body2">
                      Save for SMS. A2P 10DLC guidelines apply.
                    </Typography>
                    <label>Phone Number</label>
                    <TextField
                      label="Phone number"
                      placeholder='Save to View'
                      variant="filled"
                      fullWidth
                      value={tempAgentRevision.phoneNumber || ''}
                      disabled
                    />
                  </div>
                )}
              </ListItem> */}
            </List>
          </Collapse>
        </List>

        <div className={classes.saveButtonContainer}>
          <Button
            disabled={!hasAgentRevisionChanged(lastSavedRevision, tempAgentRevision) || selectedAgent.sharedWithUser}
            variant="contained"
            color="primary"
            onClick={handleSaveAgent}
            className={classes.saveButton}
          >
            Save
          </Button>
        </div>

      </div>
    </div>
  );
}

export default AgentDetails;

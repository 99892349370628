import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import routes from 'src/config/routes';
import { useNavigationContext } from './NavigationContext';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [heartbeatInterval, setHeartbeatInterval] = useState(null);
  const { openNavigationPane, setIsSideDrawerVisible } = useNavigationContext();

  useEffect(() => {
    const storedToken = localStorage.getItem('accessToken');
    const storedUser = JSON.parse(localStorage.getItem('authUser'));

    if (storedToken) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${storedToken}`;
      setIsLoggedIn(true);
    }

    if (storedUser) {
      setUser(storedUser);
    }
  }, []);

  useEffect(() => {
    if (!!user) {
      setHeartbeatInterval(setInterval(() => {
        axios.post(`${routes.heartbeat}`);
      }, 30000));

      openNavigationPane('agentList');
    } else {
      clearInterval(heartbeatInterval);
      setHeartbeatInterval(null);
    }
  }, [user])

  const login = (loginData) => {
    setUser(loginData);
    setIsLoggedIn(true);
    localStorage.setItem('accessToken', loginData.accessToken);
    axios.defaults.headers.common['Authorization'] = `Bearer ${loginData.accessToken}`;
    localStorage.setItem('authUser', JSON.stringify(loginData));

    openNavigationPane('agentList');
  };

  const logout = () => {
    setUser(null);
    setIsLoggedIn(false);
    setIsSideDrawerVisible(false);
    localStorage.removeItem('accessToken');
    localStorage.removeItem('authUser');
  };

  const authValue = {
    isLoggedIn,
    user,
    login,
    logout
  };

  return (
    <AuthContext.Provider value={authValue}>
      {children}
    </AuthContext.Provider>
  );
};
